import { getCookie, removeCookies, setCookies } from 'cookies-next';
import { isCrawler, isServer } from './isServer';

// Prod
export const GTM_ID = 'GTM-PQ3LLKT';
export const UA_ID = 'UA-106538319-1';
export const AW_ID = 'AW-925699234';
export const OPTIMIZE_ID = 'GTM-5WPX3W7';
export const CLICKY_ID = "101415382";
export const CLICKY_GOAL_ID = '2861';
// Dev
/*
export const GTM_ID = 'GTM-PVMV7M5';
export const UA_ID = 'UA-93814301-1';
export const AW_ID = 'AW-665';
export const OPTIMIZE_ID = 'OPT-MG2NXPD';
export const CLICKY_ID = "101415382";
export const CLICKY_GOAL_ID = '2861';
*/

export const INTERFACE_RATE = () => {
  return process.env.AB3_RATE || -1;
};
export const randomABCookie = (tag, rate, reqres) => {
  if (rate == -1) return 2;
  if (reqres.req?.headers && isCrawler(reqres.req.headers['user-agent'])) {
    return 2;
  }
  if (!rate) rate = 0.1;
  const ab = getCookie(tag, reqres);
  if (ab) {
    setCookies(tag, ab, { ...reqres, maxAge: 60 * 60 * 5 });
    return ab;
  }
  const abSelect = Math.random() < rate ? 1 : 2;
  setCookies(tag, abSelect, { ...reqres, maxAge: 60 * 60 * 5 });
  return abSelect;
};

export const randomABTag = (tag, save) => {
  const ab = sessionStorage.getItem(tag);
  if (ab) return ab;
  const abSelect = Math.random() > 0.5 ? 1 : 2;
  if (save) {
    sessionStorage.setItem(tag, abSelect);
  }
  return abSelect;
};

export const synthetic = (ev, it) => {
  try {
    let data = [];
    try {
      data = JSON.parse(sessionStorage.getItem('synthetic'));
      if (!data) data = [];
    } catch (e) {
      data = [];
    }
    data.push([new Date().toISOString(), ev, it]);
    sessionStorage.setItem('synthetic', JSON.stringify(data));
  } catch (e) {}
};

export const pageview = (url) => {
  const dim5abData = 0; //sessionStorage.getItem('dim5ab') ? sessionStorage.getItem('dim5ab') : 0;
  const dim3abData = 0; //getCookie('dim3ab') ? getCookie('dim3ab') : 2;
  const dim4abData = 0; // getCookie('dim4ab') ? getCookie('dim4ab') :
  const pos = url.indexOf('?');
  if (pos > 0 && url.indexOf('/checkout') == -1) {
    url = url.substring(0, pos);
  }
  window.dataLayer?.push({
    event: 'pageview',
    page: url,
    dim5_ab: dim5abData,
    dim4_ab: dim4abData,
    dim3_ab: dim3abData,
  });
  synthetic('pv', url);
};

export const pushLayerEvent = (event, params = null) => {
  const dim5abData = 0; // sessionStorage.getItem('dim5ab') ? sessionStorage.getItem('dim5ab') : 0;
  const dim3abData = 0; // getCookie('dim3ab') ? getCookie('dim3ab') : 2;
  const dim4abData = 0; // getCookie('dim4ab') ? getCookie('dim4ab') : 0;
  if (event !== 'conversion') {
    window.dataLayer?.push({
      event: event,
      facility_id: params?.facility_id,
      dim5_ab: dim5abData,
      dim4_ab: dim4abData,
      dim3_ab: dim3abData,
    });
  }

  window.dataLayer?.push({
    event: 'VirtualPageView',
    virtualPageURL: '/virtualpage/' + event,
    facility_id: params?.facility_id,
    dim5_ab: dim5abData,
    dim4_ab: dim4abData,
    dim3_ab: dim3abData,
  });

  window.dataLayer?.push({
    event: 'pageview',
    page: '/virtualpage/' + event,
    facility_id: params?.facility_id,
    dim5_ab: dim5abData,
    dim4_ab: dim4abData,
    dim3_ab: dim3abData,
  });

  window.mixpanel?.track(event, {
    facility_id: params?.facility_id,
    dim5_ab: dim5abData,
    dim4_ab: dim4abData,
    dim3_ab: dim3abData,
  });

  if (event === 'conversion') {
    window.dataLayer?.push({
      event: event,
      send_to: params?.send_to,
      value: params?.value,
      currency: params?.currency,
      facility_id: params?.facility_id,
      reservation_code: params?.reservation_code,
    });
    if (window.clicky) {
      window.clicky.goal( CLICKY_GOAL_ID, '0' );
    }
  }
};


import { fetchTemplate, isServer } from '@utils/isServer';
import { computed, makeAutoObservable, trace } from 'mobx';

class SearchStore {
  checkin = isServer ? null : sessionStorage.getItem('checkin');
  checkout = isServer ? null : sessionStorage.getItem('checkout');
  airport = isServer ? null : JSON.parse(sessionStorage.getItem('airport'));
  city = isServer ? null : JSON.parse(sessionStorage.getItem('city'));
  airportinitials = isServer
    ? null
    : JSON.parse(sessionStorage.getItem('airport'))?.airportinitials;

  searchResult = {};
  isLoading = false;
  isLoadedGoogle = false;
  filterPriceRange = [0, 1000];
  filterRating = 1;
  filterHighlights = [];
  sortBy = 1;
  defaultFilterPriceRange = [0, 1000];
  defaultFilterRating = 1;
  defaultFilterHighlights = [];
  defaultSortBy = 1;
  selectedFacilities = [];
  cheapestPrice = 0;
  cheapestFacility = 0;

  constructor() {
    makeAutoObservable(this);
  }

  facilityStatus({
    date_sold_out,
    not_sufficient_days,
    facility_min_days,
    facility_max_days,
  }) {
    let status;
    if (date_sold_out > 0) {
      status = 'soldout';
    }
    if (not_sufficient_days > 0) {
      status = 'unavailable';
    }
    if (date_sold_out === 0 && not_sufficient_days === 0) {
      status = 'default';
    }
    return status;
  }

  _handleSortByPriceFilter(searchResultList, sortByPrice) {
    if (sortByPrice) {
      searchResultList.result.filter((v, i) => {
        v.facility_search_order !== 1;
      });

      searchResultList.result.sort((a, b) => {
        if (a.date_sold_out > b.date_sold_out) return 1;
        if (a.is_blackout > b.is_blackout) return 1;
        if (b.date_sold_out > a.date_sold_out) return -1;
        if (b.is_blackout > a.is_blackout) return -1;
        return a.facility_selling_price > b.facility_selling_price ? 1 : -1;
      });
    }

    return searchResultList;
  }
  async subscribe(airport_id, subscriber_email, address, message) {
    const searchResponse = await fetch('/api/Subscriber/RegisterSubscriber', {
      ...fetchTemplate,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ airport_id, subscriber_email, address, message }),
    });
  }
  async fetchSearchResult(sortByPrice = null, query = null) {
    this.setIsLoading(true);

    let searchData = {
      airport_id: query?._$i
        ? !isServer && Number(window.atob(query._$i.toString()))
        : 0,
      lower_selling_price: 0,
      higher_selling_price: 0,
      lower_distance_terminal: 0,
      higher_distance_terminal: 0,
      rating: 0,
      start_date: query?.checkin,
      end_date: query?.checkout,
      order: 0,
      synthetic: sessionStorage.getItem('synthetic'),
    };

    if (query?.city) {
      Object.assign(searchData, {
        latitude: query?.lat,
        longitude: query?.lng,
        address: query?.city,
      });
    }
    if (query?.facility_id) {
      Object.assign(searchData, {
        facility_id: query?.facility_id,
      });
    }

    const phrases = [
      'Only 8 left at this price',
      'Only 2 left at this price',
      'Only 10 left at this price',
      'Only 4 left at this price',
      'Only 5 left at this price',
      '',
      '',
      '',
    ];
    const searchResponse = await fetch('/api/Facility/SearchAlternate', {
      ...fetchTemplate,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(searchData),
    });

    let searchResultList = await searchResponse.json();
    const rndIdx = Math.floor(Math.random() * phrases.length);
    if (searchResultList.result?.length > 0) {
      const rndResult = Math.floor(
        Math.random() * searchResultList.result.length
      );
      searchResultList.result[rndResult].incentive = phrases[rndIdx];
    }

    if (sortByPrice) {
      searchResultList = this._handleSortByPriceFilter(
        searchResultList,
        sortByPrice
      );
    }

    this.setAirportInitials(query?.initials);
    if (query?.city) {
      const { environment_id, result } = searchResultList;
      const filteredFacility = result.filter(
        (facility) => facility.address_distance < 10.0
      );

      let searchResultCityFiltered = {
        count: filteredFacility.length,
        environment_id,
        result: filteredFacility,
      };

      this.setSearchResult(searchResultCityFiltered);
    } else {
      this.setSearchResult(searchResultList);
    }

    this.setIsLoading(false);
  }

  setSearchResult(searchResultList) {
    this.searchResult = searchResultList;
    this.selectedFacilities = [];
  }

  getCheapest(results) {
    const lst = [...results];
    lst.sort((a, b) => b.facility_selling_price - a.facility_selling_price);
    return lst.pop();
  }

  toogleIndex(index) {
    this.searchResult.result[index].selected =
      !this.searchResult.result[index].selected;
    let selecteds = [];
    for (let i = 0; i < this.searchResult.result.length; i++) {
      if (this.searchResult.result[i].selected) {
        selecteds.push(this.searchResult.result[i].facility_id);
      }
    }
    this.selectedFacilities = selecteds;
    this.searchResult = { ...this.searchResult };
  }

  resetFacilities() {
    this.selectedFacilities = [];
    for (let i = 0; i < this.searchResult.result.length; i++) {
      this.searchResult.result[i].selected = false;
    }
    this.searchResult = { ...this.searchResult };
  }

  get filteredFacilities() {
    // apply all filters to facility list
    const fl = this.searchResult?.result?.filter((x) => {
      // Is facility not selected on map?
      if (
        this.selectedFacilities.length > 0 &&
        this.selectedFacilities.indexOf(x.facility_id) == -1
      ) {
        return false;
      }
      // Is facility selling price out of range price?
      if (this.filterPriceRange) {
        if (
          x.facility_selling_price < this.filterPriceRange[0] ||
          x.facility_selling_price > this.filterPriceRange[1]
        ) {
          return false;
        }
      }
      // Is facility rating lower that the requested?
      if (this.filterRating > 1) {
        if (this.filterRating == 2 && x.facility_review_avg < 4.5) return false;
        if (this.filterRating == 3 && x.facility_review_avg < 4.0) return false;
        if (this.filterRating == 4 && x.facility_review_avg < 3.5) return false;
      }
      // Is facility without the selected highlight
      if (
        this.filterHighlights?.includes('facility_distance') &&
        !this.isHighlightEnabled(x.highlights, 'facility_distance')
      )
        return false;
      if (
        this.filterHighlights?.includes('facility_free_cancellation') &&
        !this.isHighlightEnabled(x.highlights, 'facility_free_cancellation')
      )
        return false;
      if (
        this.filterHighlights?.includes('facility_free_shuttles') &&
        !this.isHighlightEnabled(x.highlights, 'facility_free_shuttles')
      )
        return false;
      if (
        this.filterHighlights?.includes('facility_shuttle_frequency') &&
        !this.isHighlightEnabled(x.highlights, 'facility_shuttle_frequency')
      )
        return false;
      return true;
    });

    if (fl) {
      let tempCheapestPrice = 999;
      let tempCheapestFacility;
      for (const x of fl) {
        if (tempCheapestPrice > x.facility_selling_price) {
          tempCheapestPrice = x.facility_selling_price;
          tempCheapestFacility = x.facility_id;
        }
      }
      this.cheapestPrice = tempCheapestPrice;
      this.cheapestFacility = tempCheapestFacility;
    }
    // Apply sort criteria
    if (this.sortBy == 2) {
      return fl?.sort(
        (a, b) => a.facility_selling_price - b.facility_selling_price
      );
    }
    if (this.sortBy == 3) {
      return fl?.sort(
        (a, b) => b.facility_selling_price - a.facility_selling_price
      );
    }
    return fl;
  }
  isHighlightEnabled(highlights, highlight) {
    if (!highlights) return false;
    // find in highlight array the item with type "highlight" and check
    // if the checked attribyte is true
    for (let i = 0; i < highlights.length; i++) {
      if (highlights[i].type == highlight && highlights[i].check) {
        return true;
      }
    }

    return false;
  }

  get maxPrice() {
    // search for highest price
    let mx = this.searchResult?.result
      ?.map((x) => x.facility_selling_price)
      .reduce((a, b) => (a > b ? a : b), 0);
    if (!mx) {
      // fallback (not loaded)
      mx = 16;
    }
    return mx + 4;
  }

  get minPrice() {
    // get minimum price
    let minP = this.searchResult?.result
      ?.map((x) => x.facility_selling_price)
      .reduce((a, b) => (a > b ? b : a), 10);
    return minP;
  }

  setIsLoading(value) {
    this.isLoading = value;
  }

  setAirportInitials(initials) {
    this.airportinitials = initials;
  }

  setIsLoadedGoogle(value) {
    this.isLoadedGoogle = value;
  }

  setSortBy(value) {
    this.sortBy = value;
  }

  setFilterPriceRange(value) {
    this.filterPriceRange = value;
    // this.sortBy = 2;
  }

  setFilterHighlights(value) {
    this.filterHighlights = value;
  }

  setFilterGuestRating(value) {
    this.filterRating = value;
  }
  get filterLabelRating() {
    if (this.filterRating == this.defaultFilterRating) {
      return 'Rating';
    }
    if (this.filterRating == 2) {
      return 'Excellent!';
    }
    if (this.filterRating == 3) {
      return 'Very Good!';
    }
    if (this.filterRating == 4) {
      return 'Good';
    }
  }
  get filterLabelHighlights() {
    if (this.filterHighlights.length < 1) {
      return 'Highlights';
    }
    // if (this.filterHighlights.length == 1) {
    //   return;
    // }
    return this.filterHighlights.length;
  }
  get filterLabelPriceRange() {
    if (
      (this.filterPriceRange[0] == this.defaultFilterPriceRange[0] &&
        this.filterPriceRange[1] == this.defaultFilterPriceRange[1]) ||
      (this.filterPriceRange[0] == 0 &&
        this.filterPriceRange[1] == this.maxPrice)
    ) {
      return 'Price';
    }
    return '$' + this.filterPriceRange[0] + ' - ' + this.filterPriceRange[1];
  }

  get sortLabel() {
    if (this.sortBy == 1) {
      return 'Best Match';
    }
    if (this.sortBy == 2) {
      return 'Price (low to high)';
    }
    if (this.sortBy == 3) {
      return 'Price (high to low)';
    }
  }

  get isFilterModified() {
    if (this.sortBy > 1) {
      return true;
    }
    if (
      !(
        (this.filterPriceRange[0] == this.defaultFilterPriceRange[0] &&
          this.filterPriceRange[1] == this.defaultFilterPriceRange[1]) ||
        (this.filterPriceRange[0] == 0 &&
          this.filterPriceRange[1] == this.maxPrice)
      )
    ) {
      return true;
    }
    if (this.filterRating != this.defaultFilterRating) {
      return true;
    }
    if (this.filterHighlights.length > 0) {
      return true;
    }

    return false;
  }

  resetFilter() {
    this.sortBy = this.defaultSortBy;
    this.filterHighlights = [...this.defaultFilterHighlights];
    this.filterRating = this.defaultFilterRating;
    this.filterPriceRange = [...this.defaultFilterPriceRange];
  }

  clearAirportAndCityStorages() {
    sessionStorage.removeItem('city');
    sessionStorage.removeItem('airport');
    sessionStorage.removeItem('checkin');
    sessionStorage.removeItem('checkout');
  }
}

export default SearchStore;
import { IReviews } from '@/types';
import { groupDatesByRate } from '@/utils/datesFn';
import { fetchTemplate } from '@/utils/isServer';
import dayjs, { Dayjs } from 'dayjs';

class ReservationService {
  totalLoaded = 5;
  public async GetReviews(facility_id, count, type_review) {
    const reviewsResponse = await fetch(
      `/api/FacilityReview/LoadData/${facility_id}`,
      {
        ...fetchTemplate,
        body: new URLSearchParams(
          `start=${count}&length=${this.totalLoaded}&extrasearch[type_review]=${type_review}`
        ),
        method: 'post',
      }
    );

    const reviews = await reviewsResponse.json();
    return reviews;
  }
  public async GetReviewsByStar(
    facility_id: number,
    count: number,
    type_review: number,
    star_review = 5
  ) {
    const reviewsResponse = await fetch(
      `/api/FacilityReview/LoadData/${facility_id}`,
      {
        ...fetchTemplate,
        body: new URLSearchParams(
          `start=${count}&length=${this.totalLoaded}&extrasearch[type_review]=${type_review}&extrasearch[star_review]=${star_review}`
        ),
        method: 'post',
      }
    );

    const reviews = await reviewsResponse.json();
    return reviews;
  }

  public async GetReviewsBySortStar(
    facility_id: number,
    count: number,
    type_review: number
  ) {
    const reviewsResponse = await fetch(
      `/api/FacilityReview/LoadData/${facility_id}`,
      {
        ...fetchTemplate,
        body: new URLSearchParams(
          `start=${count}&length=${this.totalLoaded}&extrasearch[type_review]=${type_review}&extrasearch[star_order]=1`
        ),
        method: 'post',
      }
    );

    const reviews = await reviewsResponse.json();
    return reviews;
  }

  public async GetReviewsThirdParty(facility_url_code): Promise<IReviews[]> {
    const reviewsResponse = await fetch(
      `/api/FacilityReview/LoadDataThirdParty/${facility_url_code}`,
      {
        ...fetchTemplate,
        body: new URLSearchParams(
          `start=0&length=${this.totalLoaded}&extrasearch[type_review]=`
        ),
        method: 'post',
      }
    );

    const reviews = await reviewsResponse.json();
    return reviews;
  }
  public async checkCoupon(coupon: string, email: string, facilityId: string) {
    const response = await fetch('/api/Coupon/Validate', {
      ...fetchTemplate,
      method: 'POST',
      body: `coupon_code=${coupon}&email=${email}&facility_id=${facilityId}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const data = await response.json();
    return data;
  }

  public async GetPrice(
    data: any,
    member_id: number,
    checkin: string,
    checkout: string
  ) {
    const abtest = sessionStorage.getItem('abtest');
    const checkout_mode = sessionStorage.getItem('checkout_mode');
    const priceResponse = await fetch(`/api/Reservation/CalculatePrice`, {
      ...fetchTemplate,
      body: new URLSearchParams(
        `facility_id=${data.facility_id}&facility_url_code=${
          data.facility_url_code
        }&reservation_dt_checkin=${dayjs(checkin).format(
          'MM.DD.YYYY h:mm a'
        )}&reservation_dt_checkout=${dayjs(checkout).format(
          'MM.DD.YYYY h:mm a'
        )}&member_id=${member_id}&coupon_code=${
          data.coupon_code ?? ''
        }&coupon_discount=${data.coupon_discount ?? ''}&reservation_fee=${
          data.reservation_fee ?? ''
        }&reservation_tax=${data.reservation_tax ?? ''}&stripeToken=${
          data.stripeToken ?? ''
        }&reservation_first_name=${
          data.reservation_first_name ?? ''
        }&reservation_last_name=${
          data.reservation_last_name ?? ''
        }&reservation_email=${data.reservation_email ?? ''}&reservation_phone=${
          data.reservation_phone ?? ''
        }&zipcode=${data.zipcode ?? ''}&card_name=${
          data.card_name ?? ''
        }&checkout_mode=${checkout_mode}&abtest=${abtest}`
      ),
      method: 'post',
    });
    const price = await priceResponse.json();
    const hasDueNow = price.dueNow !== price.totalPrice ? true : false;
    const hasDiscount = price.fullNosonFee !== price.discountedNosonFee;
    const priceDescription = Object.assign({
      hasDueNow,
      hasDiscount,
      ...price,
    });
    return priceDescription;
  }

  public async GetDailyRates(facility_id, days_parked, checkin, checkout) {
    const checkout_mode = sessionStorage.getItem('checkout_mode');
    const dailyRatesResponse = await fetch(
      `/api/facility/dates?facility_id=${facility_id}&start_date=${checkin}&end_date=${checkout}&checkout_mode=${checkout_mode}`,
      {
        ...fetchTemplate,
        method: 'post',
      }
    );

    const dailyRates = await dailyRatesResponse.json();
    const daysParked = days_parked || dailyRates.dates.length - 1;
    const datePricesMinusOne = dailyRates.dates.slice(0, daysParked);
    const groupedDates = groupDatesByRate(datePricesMinusOne);
    return groupedDates;
  }

  public getGroupedDatesReservation(
    startDate: Dayjs,
    endDate: Dayjs,
    days_parked: number,
    selling: any
  ): any {
    const dailyRates = [];
    let fail = false;
    for (let it = startDate; it.isBefore(endDate); it = it.add(1, 'day')) {
      const keyDate = it.format('YYYY-MM-DD');
      const rate = selling[keyDate];
      if (!rate) {
        fail = true;
        break;
      }
      dailyRates.push({ date: keyDate + 'T00:00:00', rate });
    }
    if (fail) return null;

    const daysParked = days_parked || dailyRates.length - 1;
    const datePricesMinusOne = dailyRates.slice(0, daysParked);
    const groupedDates = groupDatesByRate(datePricesMinusOne);

    return groupedDates;
  }
}

export default new ReservationService();

